import React, { useContext, useState } from 'react'
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal'
import BackEndAPI from '../../Services/BackEndAPI'
import { Box, Button, Grid, Paper, Typography } from '@mui/material'
import InputText from '../../DevComponents/InputText'
import { MensagemTipo } from '../../GlobalStates/MensagemState'
import { useLocation, useNavigate } from 'react-router-dom'
import { RespostaPadraoInterface } from '../../ImportBackend/Interfaces/PadraoInterfaces'
import { ClsLogout } from './LogoutCls'
import ClsValidacao from '../../Utils/ClsValidacao'

interface AlterarSenhaInterface {
  cpf: string
  email: string
}

export default function ResetSenhaCliente () {

  const { state } = useLocation()

  const ResetDados: AlterarSenhaInterface = {
    cpf: state && state.cpf ? state.cpf : '',
    email: ''
  }

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface

  const { mensagemState, setMensagemState } = ( useContext( ContextoGlobal ) as ContextoGlobalInterface )

  const [dados, setDados] = useState<AlterarSenhaInterface>( ResetDados )
  const [erros, setErros] = useState( {} )

  const navigate = useNavigate()

  const clsApi = new BackEndAPI()

  const validarDados = (): boolean => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.eCPF( 'cpf', dados, erros, retorno, false )
    retorno = clsValidacao.eEmail( 'email', dados, erros, retorno, false )

    setErros( erros )

    return retorno

  }

  const resetSenha = () => {

    if ( validarDados() ) {

      const mutation: string = `
        resetSenha(cpf: "${dados.cpf}", email: "${dados.email}") {
          ok
          mensagem
        }
      `

      clsApi.mutation<RespostaPadraoInterface>( mutation, 'resetSenha', 'Enviando Senha Por Email', contexto ).then( rs => {

        if ( rs.ok ) {

          setMensagemState( {
            ...mensagemState,
            exibir: true,
            titulo: 'Atenção!',
            mensagem: 'Você receberá uma nova senha caso exista o CPF e o EMAIL em nosso cadastro.',
            tipo: MensagemTipo.Info,
            exibirBotao: true
          } )

            ; ( new ClsLogout() ).efetuarLogout( contexto.setLoginState, contexto.setLayoutState, navigate )

        } else {
          setMensagemState( {
            ...mensagemState,
            exibir: true,
            titulo: 'Reset de Senha Não Realizado!!!',
            mensagem: rs.mensagem,
            tipo: MensagemTipo.Error,
            exibirBotao: true
          } )
        }


      } ).catch( () => {

        setMensagemState( {
          ...mensagemState,
          exibir: true,
          titulo: 'Erro!!!',
          mensagem: 'Envio de Senha Não Realizado! Consulte Suporte!!!',
          tipo: MensagemTipo.Error,
          exibirBotao: true
        } )

      } )

    }

  }

  return (
    <>
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        height='100vh'
      >
        <Grid item xs={10} sm={8} md={6} lg={4}>
          <Paper>
            <Box
              sx={{ backgroundColor: 'primary.main', padding: 2 }}
              textAlign='center'
            >
              <img src={'imagens/'.concat( process.env.REACT_APP_PASTA_IMAGENS as string ).concat( '/logoFundoBranco.png' )} width={200} alt={process.env.REACT_APP_TITULO} />
            </Box>
            <Box sx={{ backgroundColor: 'white', padding: 2, mx: 5 }}            >
              <Grid>
                <Grid item textAlign='center'>
                  <Typography variant="h6" fontFamily='sans-serif' fontWeight='bolder' color="primary.main">
                    Nova senha por e-mail
                  </Typography>
                </Grid>

                <Grid item xs={12}>

                  <InputText
                    dados={dados}
                    field='cpf'
                    label='CPF'
                    setState={setDados}
                    erros={erros}
                    maxLength={14}
                    mask='cpf'
                  />

                </Grid>

                <Grid item xs={12} >

                  <InputText
                    dados={dados}
                    field='email'
                    label='Email'
                    setState={setDados}
                    erros={erros}
                    maxLength={255}
                  />

                </Grid>

                <Grid item textAlign='right'>
                  <Button variant='contained' onClick={() => resetSenha()} sx={{ mt: 5 }}>Enviar Nova Senha</Button>
                </Grid>

              </Grid>

            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  )

}