import React, { useContext, useState } from 'react'
import { Container, Grid, IconButton, Paper, Typography } from '@mui/material';
import InputText from '../../DevComponents/InputText';
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal';
import Condicional from '../../Layout/Condicional';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import DataTable, { DataTableCabecalhoInterface } from '../../DevComponents/DataTable';
import { useNavigate } from 'react-router-dom';
import ClsCrud from '../../Utils/ClsCrud';

import { StatusForm } from '../../Utils/ClsCrud'
import ClsValidacao from '../../Utils/ClsValidacao';
import { EMDESENVOLVIMENTO } from '../../ImportBackend/Config/emDesenvolvimento';
import ClsFormatacao from '../../Utils/ClsFormatacao';
import { EquipamentoInterface } from '../../ImportBackend/Interfaces/EquipamentoInterfaces';
import PesquisarTabela from '../../DevComponents/PesquisarTabela';
import { PerfilInterface } from '../../ImportBackend/Interfaces/PerfilInterfaces';
import { validarVelocidade } from './Perfis';

interface PesquisaInterface {
  descricao: string
}

export default function Equipamentos () {

  const clsFormatacao: ClsFormatacao = new ClsFormatacao()

  const [statusForm, setStatusForm] = useState<StatusForm>( StatusForm.Pesquisando )

  const Cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'descricao',
      cabecalho: 'descricao',
      alinhamento: 'left'
    },
    {
      campo: 'mac',
      cabecalho: 'MAC',
      alinhamento: 'left'
    },
    {
      campo: 'ativo',
      cabecalho: 'Ativo',
      alinhamento: 'left',
      format: ( v ) => v ? 'Sim' : 'Não'
    },
    {
      campo: 'dataLimite',
      cabecalho: 'Data Limite',
      alinhamento: 'left',
      format: ( v ) => v && v.length > 0 ? clsFormatacao.dataISOtoUser( v ) : ''
    },
    {
      campo: 'velocidade',
      cabecalho: 'Velocidade',
      alinhamento: 'left'
    }
  ]

  const ResetDados: EquipamentoInterface =
  {
    idEquipamento: 0,
    descricao: '',
    mac: '',
    ativo: false,
    dataLimite: '',
    idPerfil: 0,
    velocidade: '0'
  }

  const TituloForm = {
    [StatusForm.Incluindo]: 'Inclusão de Novo Equipamento',
    [StatusForm.Excluindo]: 'Exclusão de Equipamento Não Utilizado',
    [StatusForm.Pesquisando]: 'Equipamentos são utilizados para Cadastro do Sistema',
    [StatusForm.Editando]: 'Alteração de Dados de Equipamentos',
    [StatusForm.Exibindo]: 'Dados do Equipamento'
  }

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  const { mensagemState, setMensagemState } = contexto

  const [dados, setDados] = useState<EquipamentoInterface>( ResetDados )

  const [erros, setErros] = useState( {} )

  const [pesquisa, setPesquisa] = useState<PesquisaInterface>( { descricao: '' } )

  const [rsPesquisa, setRsPesquisa] = useState<Array<EquipamentoInterface>>( [] )

  const navigate = useNavigate()

  const validarDados = (): boolean => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.naoVazio( 'descricao', dados, erros, retorno )
    retorno = clsValidacao.eMac( 'mac', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'idPerfil', dados, erros, retorno )

    if ( dados.dataLimite !== null )
      retorno = clsValidacao.eData( 'dataLimite', dados, erros, retorno, true )

    if ( !validarVelocidade( dados.velocidade ) ) {
      retorno = false
      erros.velocidade = 'Velocidade Inválida!'
    }

    setErros( erros )

    return retorno

  }

  const clsCrud: ClsCrud<EquipamentoInterface> = new ClsCrud(
    navigate,
    ResetDados,
    setStatusForm,
    setDados,
    setErros,
    mensagemState,
    setMensagemState,
    setRsPesquisa,
    contexto,
    validarDados,
    {
      confirmarMutation: 'updateEquipamento',
      excluirMutation: 'delEquipamento',
      campoId: 'idEquipamento',
      camposPesquisa: '{idEquipamento descricao mac ativo dataLimite velocidade}',
      pesquisaQuery: 'getEquipamentos',
      pesquisaPorId: 'getEquipamentoPorId',
      camposPesquisaPorId: '{idEquipamento descricao mac ativo dataLimite idPerfil velocidade}'
    },
    {
      confirmando: 'Atualizando Equipamento',
      erroCadastro: 'Erro ao Cadastrar Equipamento',
      erroExclusao: 'Erro ao Excluir Equipamento',
      erroPesquisa: 'Erro ao Pesquisar Equipamento',
      pesquisando: 'Pesquisando Dados de Equipamentos...',
      sucessoCadastro: 'Equipamento Cadastrado com sucesso!',
      atualizacaoSucesso: 'Equipamento Atualizado com sucesso!',
      tituloConfirmado: 'Confirmado!',
      sucessoExclusao: 'Equipamento Excluído com sucesso...',
      tituloConfirmacaoExclusao: 'Confirma?',
      tituloErroCadastro: 'Erro!',
      tituloErroExclusao: 'Erro!',
      tituloErroPesquisa: 'Erro!',
      excluindo: 'Excluindo Equipamento...'
    }
  )

  const onKeyPesquisa = () => {
    clsCrud.onClickPesquisa( pesquisa.descricao, mensagemState )
  }

  /*
  const onChangeDataLimite = ( data: string ) => {
    setDados({...dados,dataLimite: data})
    console.log(data)
  }
  */

  return (
    <>
      <Container maxWidth="md" sx={{ mt: 5 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                Cadastro de Equipamentos
                <Typography variant="body2" gutterBottom>
                  {TituloForm[statusForm]}
                </Typography>
              </Typography>

              <IconButton onClick={() => clsCrud.btFechar()}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <Condicional condicao={statusForm === StatusForm.Pesquisando}>

              <Grid item xs={12} sm={10} sx={{ mb: 3 }}>

                <InputText
                  dados={pesquisa}
                  field='descricao'
                  label='Pesquisar'
                  setState={setPesquisa}
                  iconeEnd="search"
                  onClickIconeEnd={() => clsCrud.onClickPesquisa( pesquisa.descricao, mensagemState )}
                  mapKeyPress={[{ key: 'Enter', onKey: onKeyPesquisa }]}
                />

                {/*
                // TODO - VERIFICAR KEY PRESS
                  
                */}

              </Grid>

              <Grid item xs={12} sm={2} alignSelf='center' sx={{ mt: { xs: 0, sm: 2 }, textAlign: { xs: 'right', sm: 'center' } }}>
                <Button variant='contained' onClick={() => clsCrud.btIncluir()}>Incluir</Button>
              </Grid>

            </Condicional>

            <Condicional condicao={statusForm !== StatusForm.Pesquisando}>

              <Grid item xs={12} sm={5} >

                <InputText
                  dados={dados}
                  field='descricao'
                  label='Descricao'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={50}
                />

              </Grid>

              <Grid item xs={12} sm={5} sx={{ pl: { sm: 1 } }}>
                <InputText
                  dados={dados}
                  field='mac'
                  label='Mac Address'
                  tipo='uppercase'
                  mask='mm:mm:mm:mm:mm:mm'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={50}
                />
              </Grid>

              <Grid item xs={12} sm={2} sx={{ pl: { sm: 1 } }} >

                <InputText
                  dados={dados}
                  field='velocidade'
                  label='Velocidade'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={5}
                />

              </Grid>

              <Grid item xs={12} sm={6} md={5} >

                <PesquisarTabela<PerfilInterface>
                  setState={setDados}
                  field='idPerfil'
                  fieldSet='idPerfil'
                  label='Perfil'
                  dados={dados}
                  campoQueryPesquisaID='idPerfil'
                  campoQueryPesquisa='pesquisa'
                  camposRetornoQueryPesquisa='{idPerfil, descricao}'
                  campoLabelQueryPesquisa='descricao'
                  nomeQueryPesquisa='getPerfis'
                  nomeQueryPesquisaID='getPerfilPorId'
                  mensagemPesquisa='Procurando Perfis...'
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  pesquisarTudoAoIniciar
                />

              </Grid>

              <Grid item xs={12} sm={4} md={5} sx={{ pl: { sm: 1 } }}>

                <InputText
                  dados={dados}
                  field='dataLimite'
                  label='Data Limite'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  type='date'
                />

              </Grid>

              <Grid item xs={12} sm={2} sx={{ mt: { xs: 2, sm: 4 }, pl: { md: 1 } }}>
                <InputText
                  dados={dados}
                  field='ativo'
                  label='Ativo'
                  setState={setDados}
                  tipo='checkbox'
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                />
              </Grid>

              <Grid item xs={12} sx={{ mt: 3 }}>

                <Condicional condicao={statusForm === StatusForm.Excluindo}>
                  <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => clsCrud.btConfirmarExclusao( dados, mensagemState, pesquisa.descricao )}>Confirmar</Button>
                </Condicional>

                <Condicional condicao={statusForm !== StatusForm.Excluindo}>
                  <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => clsCrud.btConfirmar( dados, mensagemState, statusForm, pesquisa.descricao )}>Confirmar</Button>
                </Condicional>

                <Button variant='contained' startIcon={<CloseIcon />} sx={{ py: 1 }} onClick={() => clsCrud.btCancelar()}>Cancelar</Button>

              </Grid>

            </Condicional>

            <Condicional condicao={statusForm === StatusForm.Pesquisando}>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <DataTable dados={rsPesquisa} cabecalho={Cabecalho} acoes={[
                  { icone: 'delete', toolTip: 'Excluir', onAcionador: clsCrud.btExcluir },
                  { icone: 'create', toolTip: 'Alterar', onAcionador: clsCrud.btEditar }]} />
              </Grid>
            </Condicional>

            <Condicional condicao={EMDESENVOLVIMENTO}>
              <p>{JSON.stringify( dados )}</p>
              <p>{JSON.stringify( erros )}</p>
            </Condicional>

          </Grid>
        </Paper>

      </Container>
    </>
  )
}