// TODO - PÁGINA INICIAL
// export const PAGINAINICIALEMDESENVOLVIMENTO: string = '/Produtos'
// export const PAGINAINICIALEMDESENVOLVIMENTO: string = '/Usuarios'
// export const PAGINAINICIALEMDESENVOLVIMENTO: string = '/Clientes'
// export const PAGINAINICIALEMDESENVOLVIMENTO: string = '/Equipamentos'
export const LOGARBACKENDAPI: boolean = false
export const LOGARBACKENDAPIRESULTADO: boolean = false
// export const PAGINAINICIALEMDESENVOLVIMENTO: string = '/ResetSenha'
 export const PAGINAINICIALEMDESENVOLVIMENTO: string = '/Parametros'
// export const PAGINAINICIALEMDESENVOLVIMENTO: string = '/'
// export const PAGINAINICIALEMDESENVOLVIMENTO: string = '/TesteBox'

export const DESATIVAR_TELA_CADASTRO_EM_DESENVOLVIMENTO: boolean = false