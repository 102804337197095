import React, { useContext, useEffect, useState } from 'react'
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal'
import BackEndAPI from '../../Services/BackEndAPI'
import { Box, Button, Grid, Paper, Typography } from '@mui/material'
import InputText from '../../DevComponents/InputText'
import { MensagemTipo } from '../../GlobalStates/MensagemState'
import { useParams } from 'react-router-dom'
import { RespostaPadraoInterface } from '../../ImportBackend/Interfaces/PadraoInterfaces'
import { ParametrosInterface } from '../../ImportBackend/Interfaces/ParametrosInterfaces'

interface NovaSenhaInterface {
  novaSenha: string
  confirmarNovaSenha: string
}

export default function AlterarSenhaCliente () {

  const abortController: AbortController = new AbortController()

  const params = useParams()

  const ResetDados: NovaSenhaInterface = {
    novaSenha: '',
    confirmarNovaSenha: ''
  }

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface

  const { mensagemState, setMensagemState } = ( useContext( ContextoGlobal ) as ContextoGlobalInterface )

  const [dados, setDados] = useState<NovaSenhaInterface>( ResetDados )
  const [erros, setErros] = useState( {} )

  const [urlPosCadastro, setUrlPosCadastro] = useState( '/' )

  const clsApi = new BackEndAPI()

  const validarDados = (): boolean => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    if ( dados.novaSenha.length < 6 || dados.novaSenha.length > 15 ) {
      erros.novaSenha = 'Senha deve estar entre 6 e 15 caracteres'
      retorno = false
    }

    if ( dados.confirmarNovaSenha !== dados.novaSenha ) {
      erros.confirmarNovaSenha = 'Senha e Nova Senha devem ser iguais'
      retorno = false
    }

    setErros( erros )

    return retorno

  }

  const confirmarAlteracaoSenha = () => {

    if ( validarDados() ) {

      const mutation: string = `
        AlterarSenhaCliente(novaSenha: "${dados.novaSenha}", token: "${params.token}", idCliente: ${params.idCliente}) {
          ok
          mensagem
        }
      `

      clsApi.mutation<RespostaPadraoInterface>( mutation, 'AlterarSenhaCliente', 'Alterando a Senha...', contexto ).then( rs => {

        if ( rs.ok ) {

          setMensagemState( {
            ...mensagemState,
            exibir: true,
            titulo: 'Senha Alterada!',
            mensagem: 'Você deve fazer seu Login com a Nova Senha!',
            tipo: MensagemTipo.Info,
            exibirBotao: true
          } )

          window.location.href = urlPosCadastro

        } else {
          setMensagemState( {
            ...mensagemState,
            exibir: true,
            titulo: 'Erro ao Alterar Senha!',
            mensagem: 'Tente novamente!',
            tipo: MensagemTipo.Error,
            exibirBotao: true
          } )
        }

        // ( new ClsLogout() ).efetuarLogout( contexto.setLoginState, contexto.setLayoutState, navigate )

      } ).catch( () => {

        setMensagemState( {
          ...mensagemState,
          exibir: true,
          titulo: 'Erro!!!',
          mensagem: 'Alteração de Senha Não Realizada! Consulte Suporte!!!',
          tipo: MensagemTipo.Error,
          exibirBotao: true
        } )

      } )

    }

  }

  useEffect( () => {

    const query: string = `
      getParametros {
        preCadastroSiteRedirecionamento
      }
    `

    clsApi.query<ParametrosInterface>( query, 'getParametros', 'Pesquisando Parâmetros...', contexto, abortController ).then( rs => {

      setMensagemState( { ...mensagemState, exibir: false } )
      setUrlPosCadastro( rs.preCadastroSiteRedirecionamento )

    } )

    return () => {

      abortController.abort()

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] )

  return (
    <>
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        height='100vh'
      >
        <Grid item xs={10} sm={8} md={6} lg={4}>
          <Paper>
            <Box
              sx={{ backgroundColor: 'primary.main', padding: 2 }}
              textAlign='center'
            >
              <img src={'../../imagens/'.concat( process.env.REACT_APP_PASTA_IMAGENS as string ).concat( '/logoFundoBranco.png' )} width={200} alt={process.env.REACT_APP_TITULO} />
            </Box>
            <Box sx={{ backgroundColor: 'white', padding: 2, mx: 5 }}            >
              <Grid>
                <Grid item textAlign='center'>
                  <Typography variant="h6" fontFamily='sans-serif' fontWeight='bolder' color="primary.main">
                    Altere sua Senha
                  </Typography>
                </Grid>

                <Grid item xs={12}>

                  <InputText
                    dados={dados}
                    field='novaSenha'
                    label='Senha'
                    setState={setDados}
                    erros={erros}
                    maxLength={15}
                  />

                </Grid>

                <Grid item xs={12} >

                  <InputText
                    dados={dados}
                    field='confirmarNovaSenha'
                    label='Confirme'
                    setState={setDados}
                    erros={erros}
                    maxLength={15}
                  />

                </Grid>

                <Grid item textAlign='right'>
                  <Button variant='contained' onClick={() => confirmarAlteracaoSenha()} sx={{ mt: 5 }}>Confirmar Alteração de Senha</Button>
                </Grid>

              </Grid>

            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  )

}