import React, { useContext, useState } from 'react'
import { Box, Container, Grid, IconButton, Paper, Typography } from '@mui/material';
import InputText from '../../DevComponents/InputText';
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal';
import Condicional from '../../Layout/Condicional';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import DataTable, { DataTableCabecalhoInterface } from '../../DevComponents/DataTable';
import { useNavigate } from 'react-router-dom';
import ClsCrud from '../../Utils/ClsCrud';

import { StatusForm } from '../../Utils/ClsCrud'
import ClsValidacao from '../../Utils/ClsValidacao';
import { EMDESENVOLVIMENTO } from '../../ImportBackend/Config/emDesenvolvimento';
import { PerfilInterface } from '../../ImportBackend/Interfaces/PerfilInterfaces';

interface PesquisaInterface {
  descricao: string
}

export const validarVelocidade = ( velocidade: string ): boolean => {

  let retorno: boolean = true

  const NumeroEFinalLetra = new RegExp( '^[0-9]{1,3}[MGK]$', 'gm' )

  // ^[0-9]{1,3}[MGK]$

  // Se tamanho for 1 e não for zero
  if ( !velocidade || ( velocidade.length === 1 && velocidade !== '0' ) || velocidade.length === 0 ) {
    retorno = false
  }

  if ( velocidade.length > 1 ) {

    if ( !NumeroEFinalLetra.test( velocidade ) ) {

      retorno = false

    }

  }

  return retorno

}

export default function Perfis () {

  const [statusForm, setStatusForm] = useState<StatusForm>( StatusForm.Pesquisando )

  const Cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'descricao',
      cabecalho: 'Descrição',
      alinhamento: 'left'
    },
    {
      campo: 'velocidade',
      cabecalho: 'Velocidade',
      alinhamento: 'left'
    },
    {
      campo: 'sessoesSimultaneas',
      cabecalho: 'Sessões Permitidas',
      alinhamento: 'left'
    },
    {
      campo: 'acessoLiberado',
      cabecalho: 'Liberação Total',
      alinhamento: 'left',
      format: ( v ) => v ? 'Sim' : 'Não'
    },
    {
      campo: 'horasAcesso',
      cabecalho: 'QTD Horas',
      alinhamento: 'right'
    },
    {
      campo: 'horarioInicio',
      cabecalho: 'Hr. Início',
      alinhamento: 'right'
    },
    {
      campo: 'horarioTermino',
      cabecalho: 'Hr. Término',
      alinhamento: 'right'
    },
    {
      campo: 'domingo',
      cabecalho: 'Domingo',
      alinhamento: 'left',
      format: ( v ) => v ? 'Sim' : 'Não'
    },
    {
      campo: 'segunda',
      cabecalho: 'Segunda',
      alinhamento: 'left',
      format: ( v ) => v ? 'Sim' : 'Não'
    },
    {
      campo: 'terca',
      cabecalho: 'Terça',
      alinhamento: 'left',
      format: ( v ) => v ? 'Sim' : 'Não'
    },
    {
      campo: 'quarta',
      cabecalho: 'Quarta',
      alinhamento: 'left',
      format: ( v ) => v ? 'Sim' : 'Não'
    },
    {
      campo: 'quinta',
      cabecalho: 'Quinta',
      alinhamento: 'left',
      format: ( v ) => v ? 'Sim' : 'Não'
    },
    {
      campo: 'sexta',
      cabecalho: 'Sexta',
      alinhamento: 'left',
      format: ( v ) => v ? 'Sim' : 'Não'
    },
    {
      campo: 'sabado',
      cabecalho: 'Sábado',
      alinhamento: 'left',
      format: ( v ) => v ? 'Sim' : 'Não'
    }
  ]

  const ResetDados: PerfilInterface =
  {
    idPerfil: 0,
    descricao: '',
    horasAcesso: 0,
    acessoLiberado: false,
    horarioInicio: '',
    horarioTermino: '',
    segunda: false,
    terca: false,
    quarta: false,
    quinta: false,
    sexta: false,
    sabado: false,
    domingo: false,
    velocidade: '0',
    sessoesSimultaneas: 1
  }

  const TituloForm = {
    [StatusForm.Incluindo]: 'Inclusão de Novo Perfil',
    [StatusForm.Excluindo]: 'Exclusão de Perfil Não Utilizado',
    [StatusForm.Pesquisando]: 'Perfis são utilizados para Cadastro do Sistema',
    [StatusForm.Editando]: 'Alteração de Dados de Perfis',
    [StatusForm.Exibindo]: 'Dados do Perfil'
  }

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  const { mensagemState, setMensagemState } = contexto

  const [dados, setDados] = useState<PerfilInterface>( ResetDados )

  const [erros, setErros] = useState( {} )

  const [pesquisa, setPesquisa] = useState<PesquisaInterface>( { descricao: '' } )

  const [rsPesquisa, setRsPesquisa] = useState<Array<PerfilInterface>>( [] )

  const navigate = useNavigate()

  const validarDados = (): boolean => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.naoVazio( 'descricao', dados, erros, retorno )

    if ( !validarVelocidade( dados.velocidade ) ) {
      retorno = false
      erros.velocidade = 'Velocidade Inválida!'
    }

    setErros( erros )

    return retorno

  }

  const clsCrud: ClsCrud<PerfilInterface> = new ClsCrud(
    navigate,
    ResetDados,
    setStatusForm,
    setDados,
    setErros,
    mensagemState,
    setMensagemState,
    setRsPesquisa,
    contexto,
    validarDados,
    {
      confirmarMutation: 'updatePerfil',
      excluirMutation: 'delPerfil',
      campoId: 'idPerfil',
      camposPesquisa: '{idPerfil descricao horasAcesso acessoLiberado horarioInicio horarioTermino segunda terca quarta quinta sexta sabado domingo velocidade sessoesSimultaneas}',
      pesquisaQuery: 'getPerfis',
      pesquisaPorId: 'getPerfilPorId',
      camposPesquisaPorId: '{idPerfil descricao horasAcesso acessoLiberado horarioInicio horarioTermino segunda terca quarta quinta sexta sabado domingo velocidade sessoesSimultaneas}'
    },
    {
      confirmando: 'Atualizando Perfil',
      erroCadastro: 'Erro ao Cadastrar Perfil',
      erroExclusao: 'Erro ao Excluir Perfil',
      erroPesquisa: 'Erro ao Pesquisar Perfil',
      pesquisando: 'Pesquisando Dados de Perfis...',
      sucessoCadastro: 'Perfil Cadastrado com sucesso!',
      atualizacaoSucesso: 'Perfil Atualizado com sucesso!',
      tituloConfirmado: 'Confirmado!',
      sucessoExclusao: 'Perfil Excluído com sucesso...',
      tituloConfirmacaoExclusao: 'Confirma?',
      tituloErroCadastro: 'Erro!',
      tituloErroExclusao: 'Erro!',
      tituloErroPesquisa: 'Erro!',
      excluindo: 'Excluindo Perfil...'
    }
  )

  const onKeyPesquisa = () => {
    clsCrud.onClickPesquisa( pesquisa.descricao, mensagemState )
  }

  const liberacaoPorDiaOuHorario = (): boolean => {
    return dados.horarioInicio.length > 0
      || dados.horarioTermino.length > 0
      || dados.segunda
      || dados.terca
      || dados.quarta
      || dados.quinta
      || dados.sexta
      || dados.sabado
      || dados.domingo
  }

  return (
    <>
      <Container maxWidth="md" sx={{ mt: 5 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                Cadastro de Perfis
                <Typography variant="body2" gutterBottom>
                  {TituloForm[statusForm]}
                </Typography>
              </Typography>

              <IconButton onClick={() => clsCrud.btFechar()}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <Condicional condicao={statusForm === StatusForm.Pesquisando}>

              <Grid item xs={12} sm={10} sx={{ mb: 3 }}>

                <InputText
                  dados={pesquisa}
                  field='descricao'
                  label='Pesquisar'
                  setState={setPesquisa}
                  iconeEnd="search"
                  onClickIconeEnd={() => clsCrud.onClickPesquisa( pesquisa.descricao, mensagemState )}
                  mapKeyPress={[{ key: 'Enter', onKey: onKeyPesquisa }]}
                />

                {/*
                // TODO - VERIFICAR KEY PRESS
                  
                */}

              </Grid>

              <Grid item xs={12} sm={2} alignSelf='center' sx={{ mt: { xs: 0, sm: 2 }, textAlign: { xs: 'right', sm: 'center' } }}>
                <Button variant='contained' onClick={() => clsCrud.btIncluir()}>Incluir</Button>
              </Grid>

            </Condicional>

            <Condicional condicao={statusForm !== StatusForm.Pesquisando}>

              <Grid item xs={9}>

                <InputText
                  dados={dados}
                  field='descricao'
                  label='Descricao'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={50}
                />

              </Grid>

              <Grid item xs={3} sx={{ pl: 1 }} >

                <InputText
                  dados={dados}
                  field='velocidade'
                  label='Velocidade'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={5}
                />

              </Grid>
              {/* sm={4} sx={{ pl: { sm: 1 } }} */}

              <Grid item xs={6}  >

                <InputText
                  dados={dados}
                  field='sessoesSimultaneas'
                  label='Sessões Simultâneas'
                  tipo='number'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={3}
                />

              </Grid>

              <Grid item xs={6} sx={{ pl: 1 }}>
                <InputText
                  dados={dados}
                  field='horasAcesso'
                  label='QTD Horas'
                  setState={setDados}
                  tipo='number'
                  mask='000'
                  disabled={statusForm === StatusForm.Excluindo || dados.acessoLiberado || liberacaoPorDiaOuHorario()}
                  erros={erros}
                />
              </Grid>

              <Grid item xs={12}>
                <InputText
                  dados={dados}
                  field='acessoLiberado'
                  label='Liberado'
                  setState={setDados}
                  tipo='checkbox'
                  disabled={statusForm === StatusForm.Excluindo || dados.horasAcesso > 0 || liberacaoPorDiaOuHorario()}
                  erros={erros}
                />
              </Grid>

              <Grid item xs={6}>

                <InputText
                  dados={dados}
                  field='horarioInicio'
                  label='Hr. Início'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo || dados.acessoLiberado || dados.horasAcesso > 0}
                  erros={erros}
                  type='time'
                />

              </Grid>

              <Grid item xs={6} sx={{ pl: 1 }}>

                <InputText
                  dados={dados}
                  field='horarioTermino'
                  label='Hr. Término'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo || dados.acessoLiberado || dados.horasAcesso > 0}
                  erros={erros}
                  type='time'
                />

              </Grid>

              <Grid item xs={12} sm={8} sx={{ mt: { xs: 2, sm: 4 }, pl: { sm: 1 } }}>
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'space-around'
                }}
                >
                  <InputText
                    dados={dados}
                    field='domingo'
                    label='D'
                    setState={setDados}
                    tipo='checkbox'
                    disabled={statusForm === StatusForm.Excluindo || dados.acessoLiberado || dados.horasAcesso > 0}
                    erros={erros}
                  />
                  <InputText
                    dados={dados}
                    field='segunda'
                    label='S'
                    setState={setDados}
                    tipo='checkbox'
                    disabled={statusForm === StatusForm.Excluindo || dados.acessoLiberado || dados.horasAcesso > 0}
                    erros={erros}
                  />
                  <InputText
                    dados={dados}
                    field='terca'
                    label='T'
                    setState={setDados}
                    tipo='checkbox'
                    disabled={statusForm === StatusForm.Excluindo || dados.acessoLiberado || dados.horasAcesso > 0}
                    erros={erros}
                  />
                  <InputText
                    dados={dados}
                    field='quarta'
                    label='Q'
                    setState={setDados}
                    tipo='checkbox'
                    disabled={statusForm === StatusForm.Excluindo || dados.acessoLiberado || dados.horasAcesso > 0}
                    erros={erros}
                  />
                  <InputText
                    dados={dados}
                    field='quinta'
                    label='Q'
                    setState={setDados}
                    tipo='checkbox'
                    disabled={statusForm === StatusForm.Excluindo || dados.acessoLiberado || dados.horasAcesso > 0}
                    erros={erros}
                  />
                  <InputText
                    dados={dados}
                    field='sexta'
                    label='S'
                    setState={setDados}
                    tipo='checkbox'
                    disabled={statusForm === StatusForm.Excluindo || dados.acessoLiberado || dados.horasAcesso > 0}
                    erros={erros}
                  />
                  <InputText
                    dados={dados}
                    field='sabado'
                    label='S'
                    setState={setDados}
                    tipo='checkbox'
                    disabled={statusForm === StatusForm.Excluindo || dados.acessoLiberado || dados.horasAcesso > 0}
                    erros={erros}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sx={{ mt: 3 }}>

                <Condicional condicao={statusForm === StatusForm.Excluindo}>
                  <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => clsCrud.btConfirmarExclusao( dados, mensagemState, pesquisa.descricao )}>Confirmar</Button>
                </Condicional>

                <Condicional condicao={statusForm !== StatusForm.Excluindo}>
                  <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => clsCrud.btConfirmar( dados, mensagemState, statusForm, pesquisa.descricao )}>Confirmar</Button>
                </Condicional>

                <Button variant='contained' startIcon={<CloseIcon />} sx={{ py: 1 }} onClick={() => clsCrud.btCancelar()}>Cancelar</Button>

              </Grid>

            </Condicional>

            <Condicional condicao={statusForm === StatusForm.Pesquisando}>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <DataTable dados={rsPesquisa} cabecalho={Cabecalho} acoes={[
                  { icone: 'delete', toolTip: 'Excluir', onAcionador: clsCrud.btExcluir },
                  { icone: 'create', toolTip: 'Alterar', onAcionador: clsCrud.btEditar }]} />
              </Grid>
            </Condicional>

            <Condicional condicao={EMDESENVOLVIMENTO}>
              <p>{JSON.stringify( dados )}</p>
              <p>{JSON.stringify( erros )}</p>
            </Condicional>

          </Grid>
        </Paper>

      </Container>
    </>
  )
}