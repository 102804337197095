import React from 'react'

import { createBrowserRouter } from 'react-router-dom'
import ErroAplicacao from '../Layout/ErroAplicacao'
import Layout from '../Layout/Layout'
import ExemploMenu from '../exemplo_apagar/menu'
import TesteBox from '../exemplo_apagar/TesteBox'
import Usuarios from '../View/Crud/Sistema/Usuarios'
import Grupos from '../View/Crud/Sistema/Grupos'
import Logout from '../View/Controller/Logout'
import AlterarSenha from '../View/Controller/AlterarSenha'
import Clientes from '../View/Crud/Clientes'
import Perfis from '../View/Crud/Perfis'
import PreCadastroCliente from '../View/PreCadastro/PreCadastroCliente'
import Login from '../View/Controller/Login'
import Equipamentos from '../View/Crud/Equipamentos'
import Parametros from '../View/Controller/Parametros'
import ResetSenhaCliente from '../View/Controller/ResetSenhaCliente'
import AlterarSenhaCliente from '../View/Controller/AlterarSenhaCliente'

export const router = createBrowserRouter( [
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErroAplicacao />,
    children: [
      {
        path: "AlterarSenha",
        element: <AlterarSenha />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "Grupos",
        element: <Grupos />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "Logout",
        element: <Logout />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "Usuarios",
        element: <Usuarios />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "Clientes",
        element: <Clientes />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "Equipamentos",
        element: <Equipamentos />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "Parametros",
        element: <Parametros />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "Perfis",
        element: <Perfis />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "Login",
        element: <Login />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "login",
        element: <Login />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "admin",
        element: <Login />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "Admin",
        element: <Login />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "/PreCadastroCliente",
        element: <PreCadastroCliente />
      },
      {
        path: "/ResetSenha",
        element: <ResetSenhaCliente />
      },
      {
        path: "/AlterarSenhaCliente/:token/:idCliente",
        element: <AlterarSenhaCliente />
      }
    ]
  },
  {
    path: "/ErroAplicacao",
    element: <ErroAplicacao />
  },
  {
    path: "/ExemploMenu",
    element: <ExemploMenu />
  },
  {
    path: "/TesteBox",
    element: <TesteBox />
  }
] );