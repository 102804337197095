/*
interface SISTEMA_PERMISSOES_INTERFACE {
  [key: string]: {
    MODULO: string
    PERMISSOES: {
      [key: string]: string
      // EXCLUIR: string,

    }
  }
}
*/

// TODO - Criar Novo Crud

export interface SISTEMA_PERMISSOES_INTERFACE {
  CLIENTES: {
    MODULO: string
    PERMISSOES: {
      MANUTENCAO: string
    }
  },

  EQUIPAMENTOS: {
    MODULO: string
    PERMISSOES: {
      MANUTENCAO: string
    }
  },

  PARAMETROS: {
    MODULO: string
    PERMISSOES: {
      MANUTENCAO: string
    }
  },

  PERFIS: {
    MODULO: string
    PERMISSOES: {
      MANUTENCAO: string
    }
  },

  GRUPOS: {
    MODULO: string
    PERMISSOES: {
      MANUTENCAO: string
    }
  },

  USUARIOS: {
    MODULO: string
    PERMISSOES: {
      MANUTENCAO: string
    }
  }
}

export const MAXIMO_ERRO_LOGIN: number = 3

export const SISTEMA_PERMISSOES: SISTEMA_PERMISSOES_INTERFACE = {

  CLIENTES: {
    MODULO: 'Cadastro de Clientes',
    PERMISSOES: {
      MANUTENCAO: 'Manutenção de Clientes'
    }
  },

  EQUIPAMENTOS: {
    MODULO: 'Cadastro de Equipamentos',
    PERMISSOES: {
      MANUTENCAO: 'Manutenção de Equipamentos'
    }
  },

  PARAMETROS: {
    MODULO: 'Parâmetros do Sistema',
    PERMISSOES: {
      MANUTENCAO: 'Manutenção de Parâmetros'
    }
  },

  PERFIS: {
    MODULO: 'Perfis de acesso',
    PERMISSOES: {
      MANUTENCAO: 'Manutenção de Perfis'
    }
  },

  GRUPOS: {
    MODULO: 'Cadastro de Grupos de Usuários',
    PERMISSOES: {
      MANUTENCAO: 'Manutenção de Grupos'
    }
  },

  USUARIOS: {
    MODULO: 'Cadastro de Usuário',
    PERMISSOES: {
      MANUTENCAO: 'Manutenção de Usuários'
    }
  }
}
