"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Logger = /** @class */ (function () {
    function Logger() {
    }
    /**
     * Loga o argumento <Oque> no console
     * @param Oque O que logar
     * @param Titulo Titulo a ser impresso separado
     * @param Separador Se separa a mensagem das outras
     */
    Logger.prototype.logar = function (Oque, Titulo, Separador) {
        if (Titulo === void 0) { Titulo = ''; }
        if (Separador === void 0) { Separador = true; }
        /* eslint-disable no-console */
        console.log((Separador ? '\n\n=======' : '') +
            (Titulo ? '>>>' + Titulo : '') +
            ':\t' +
            Oque);
        /* eslint-enable no-console */
    };
    /**
     * Limpa o console e executa método logar em seguida
     */
    Logger.prototype.clear = function (Oque, Titulo, Separador) {
        if (Titulo === void 0) { Titulo = ''; }
        if (Separador === void 0) { Separador = true; }
        console.clear();
        this.logar(Oque, Titulo, Separador);
    };
    /**
     * Executa um JSON.stringfy para o argumento
     */
    Logger.prototype.objeto = function (Oque, Titulo, Separador) {
        if (Titulo === void 0) { Titulo = ''; }
        if (Separador === void 0) { Separador = true; }
        /*
        Oque.forEach((element) => {
          console.log(element)
        })
        */
        this.logar(JSON.stringify(Oque, undefined, 2), Titulo, Separador);
    };
    /**
     * Reporta erros de Acesso negado na aplicacao
     * @param Origem Origem do Erro - Normalmente nome do Arquivo
     * @param Procedimento Nome do procedimento ou função que gerou o erro
     * @param DadosAcesso Objeto de Acesso negado / Dados do acesso
     */
    Logger.prototype.reportarAcessoNegado = function (Origem, Procedimento, DadosAcesso) {
        if (Origem === void 0) { Origem = ''; }
        if (Procedimento === void 0) { Procedimento = ''; }
        if (DadosAcesso === void 0) { DadosAcesso = {}; }
        var separador = '=============================================================';
        var espacamento = '\n\n\n\n\n\n\n';
        console.log(espacamento);
        console.log(separador);
        console.log('ERRO - Negacao de Acesso');
        console.log(separador);
        console.log('Origem......: '.concat(Origem));
        console.log('Procedimento: '.concat(Procedimento));
        console.log(DadosAcesso);
        console.log(separador);
        console.log(espacamento);
    };
    /**
     * //FIXME Emitir Erros ao administrador do sistema
     * Reporta Erros Críticos da Aplicacao
     * @param Origem Origem do Erro - Normalmente nome do Arquivo
     * @param Procedimento Nome do procedimento ou função que gerou o erro
     * @param DadosAcesso Objeto de Acesso negado / Dados do acesso
     */
    Logger.prototype.reportarErroAplicacao = function (Origem, Procedimento, ObjetoErro) {
        if (Origem === void 0) { Origem = ''; }
        if (Procedimento === void 0) { Procedimento = ''; }
        if (ObjetoErro === void 0) { ObjetoErro = {}; }
        var separador = '=============================================================';
        var espacamento = '\n\n\n\n\n\n\n';
        console.log(espacamento);
        console.log(separador);
        console.log('Erro Critico da Aplicacao');
        console.log(separador);
        console.log('Origem......: '.concat(Origem));
        console.log('Procedimento: '.concat(Procedimento));
        console.log(ObjetoErro);
        console.log(separador);
        console.log(espacamento);
    };
    return Logger;
}());
exports.default = new Logger();
