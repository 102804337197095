import React, { useContext, useState } from 'react'
import { Container, Button, Grid, IconButton, Paper, Typography } from '@mui/material';
import InputText from '../../DevComponents/InputText';
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal';
import Condicional from '../../Layout/Condicional';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import DataTable, { DataTableCabecalhoInterface } from '../../DevComponents/DataTable';
import { useNavigate } from 'react-router-dom';
import ClsCrud, { StatusForm } from '../../Utils/ClsCrud';

import ClsValidacao from '../../Utils/ClsValidacao';
import { EMDESENVOLVIMENTO } from '../../ImportBackend/Config/emDesenvolvimento';
import ClsCep from '../../Utils/ClsCep';
import PesquisarTabela from '../../DevComponents/PesquisarTabela';
import BackEndAPI from '../../Services/BackEndAPI';
import { PerfilInterface } from '../../ImportBackend/Interfaces/PerfilInterfaces';
import { ClienteInterface, ClientePesquisaInputInterface } from '../../ImportBackend/Interfaces/ClienteInterfaces';
import ClientesAtribuirSenha from './ClientesAtribuirSenha';
import { RespostaPadraoInterface } from '../../ImportBackend/Interfaces/PadraoInterfaces';
import { MensagemTipo } from '../../GlobalStates/MensagemState';
import ClsFormatacao from '../../Utils/ClsFormatacao';
import ComboBox from '../../DevComponents/ComboBox';
import { validarVelocidade } from './Perfis';

export default function Clientes () {

  const clsFormatacao: ClsFormatacao = new ClsFormatacao()

  const [atribuirSenha, setAtribuirSenha] = useState( false )

  const [statusForm, setStatusForm] = useState<StatusForm>( StatusForm.Pesquisando )
  const clsApi = new BackEndAPI()

  const Cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'nome',
      cabecalho: 'Nome',
      alinhamento: 'left'
    },
    {
      campo: 'cpf',
      cabecalho: 'CPF',
      alinhamento: 'left'
    },
    // idCliente nome cpf tentativaLogin ativo preCadastro dataLimite
    {
      campo: 'tentativaLogin',
      cabecalho: 'QTD Logins',
      alinhamento: 'right'
    },
    {
      campo: 'ativo',
      cabecalho: 'Ativo',
      alinhamento: 'left',
      format: ( v ) => v ? 'Sim' : 'Não'
    },
    {
      campo: 'preCadastro',
      cabecalho: 'Pré Cadastro',
      alinhamento: 'left',
      format: ( v ) => v ? 'Sim' : 'Não'
    },
    {
      campo: 'dataLimite',
      cabecalho: 'Data Limite',
      alinhamento: 'left',
      format: ( v ) => v && v.length > 0 ? clsFormatacao.dataISOtoUser( v ) : ''
    },
    {
      campo: 'velocidade',
      cabecalho: 'Velocidade',
      alinhamento: 'left'
    },
    {
      campo: 'sessoesSimultaneas',
      cabecalho: 'Sessões Permitidas',
      alinhamento: 'left'
    },
  ]

  const ResetDados: ClienteInterface = EMDESENVOLVIMENTO ? {
    idCliente: 0,
    nome: 'Joaquim José',
    cep: '35.500-01',
    endereco: '',
    bairro: '',
    cidade: '',
    uf: '',
    cpf: '946.213.216-09',
    rg: '',
    email: '',
    whatsAPP: '',
    fone: '',
    ativo: false,
    senha: '',
    preCadastro: true,
    dataLimite: '',
    idPerfil: 0,
    tentativaLogin: 0,
    velocidade: '0',
    sessoesSimultaneas: 1
  } :
    {
      idCliente: 0,
      nome: '',
      cep: '',
      endereco: '',
      bairro: '',
      cidade: '',
      uf: '',
      rg: '',
      cpf: '',
      email: '',
      whatsAPP: '',
      fone: '',
      ativo: false,
      senha: '',
      preCadastro: true,
      dataLimite: '',
      idPerfil: 0,
      tentativaLogin: 0,
      velocidade: '0',
      sessoesSimultaneas: 1
    }

  const TituloForm = {
    [StatusForm.Incluindo]: 'Inclusão de Novo Cliente',
    [StatusForm.Excluindo]: 'Exclusão de Cliente Não Utilizado',
    [StatusForm.Pesquisando]: 'Clientes são utilizados para Cadastro do Sistema',
    [StatusForm.Editando]: 'Alteração de Dados de Clientes',
    [StatusForm.Exibindo]: 'Dados do Cliente'
  }

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  const { mensagemState, setMensagemState } = contexto

  const [dados, setDados] = useState<ClienteInterface>( ResetDados )
  const [cpfJaCadastrado, setCpfJaCadastrado] = useState<boolean>( false )

  const [erros, setErros] = useState( {} )

  const [pesquisa, setPesquisa] = useState<ClientePesquisaInputInterface>( {
    descricao: '',
    clientesAtivos: 'T',
    clientesEmPreCadastro: 'T'
  } )

  const [rsPesquisa, setRsPesquisa] = useState<Array<ClienteInterface>>( [] )

  const navigate = useNavigate()

  const validarDados = (): boolean => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.eCPF( 'cpf', dados, erros, retorno, false )

    if ( retorno ) {
      if ( cpfJaCadastrado ) {
        retorno = false
        erros = { ...erros, cpf: 'Já Cadastrado!' }
      }
    }

    retorno = clsValidacao.naoVazio( 'nome', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'cep', dados, erros, retorno )
    // retorno = clsValidacao.naoVazio( 'tipo', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'endereco', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'bairro', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'cidade', dados, erros, retorno )
    retorno = clsValidacao.eUF( 'uf', dados, erros, retorno, false )
    retorno = clsValidacao.naoVazio( 'idPerfil', dados, erros, retorno )
    // retorno = clsValidacao.naoVazio( 'sessoesSimultaneas', dados, erros, retorno )
    retorno = clsValidacao.eEmail( 'email', dados, erros, retorno, true )
    retorno = clsValidacao.eTelefone( 'whatsAPP', dados, erros, retorno, true )
    retorno = clsValidacao.eTelefone( 'fone', dados, erros, retorno, true )
    if ( dados.dataLimite !== null )
      retorno = clsValidacao.eData( 'dataLimite', dados, erros, retorno, true )

    if ( !validarVelocidade( dados.velocidade ) ) {
      retorno = false
      erros.velocidade = 'Velocidade Inválida!'
    }

    setErros( erros )

    return retorno

  }

  const clsCrud: ClsCrud<ClienteInterface> = new ClsCrud(
    navigate,
    ResetDados,
    setStatusForm,
    setDados,
    setErros,
    mensagemState,
    setMensagemState,
    setRsPesquisa,
    contexto,
    validarDados,
    {
      confirmarMutation: 'updateCliente',
      excluirMutation: 'delCliente',
      campoId: 'idCliente',
      camposPesquisa: '{idCliente nome cpf tentativaLogin ativo preCadastro dataLimite velocidade sessoesSimultaneas}',
      pesquisaQuery: 'getClientes',
      pesquisaPorId: 'getClientePorId',
      camposPesquisaPorId: '{idCliente nome cep endereco bairro cidade uf cpf rg email whatsAPP fone ativo senha preCadastro tentativaLogin dataLimite idPerfil velocidade sessoesSimultaneas}'
    },
    {
      confirmando: 'Atualizando Cliente',
      erroCadastro: 'Erro ao Cadastrar Cliente',
      erroExclusao: 'Erro ao Excluir Cliente',
      erroPesquisa: 'Erro ao Pesquisar Cliente',
      pesquisando: 'Pesquisando Dados de Clientes...',
      sucessoCadastro: 'Cliente Cadastrado com sucesso!',
      atualizacaoSucesso: 'Cliente Atualizado com sucesso!',
      tituloConfirmado: 'Confirmado!',
      sucessoExclusao: 'Cliente Excluído com sucesso...',
      tituloConfirmacaoExclusao: 'Confirma?',
      tituloErroCadastro: 'Erro!',
      tituloErroExclusao: 'Erro!',
      tituloErroPesquisa: 'Erro!',
      excluindo: 'Excluindo Cliente...'
    }
  )

  const onKeyPesquisa = () => {
    clsCrud.onClickPesquisa( pesquisa, mensagemState )
  }

  const alterarCep = ( novoCep: any ): void => {
    if ( novoCep.length === 10 ) {

      let clsCep: ClsCep = new ClsCep()

      clsCep.buscarCep( setMensagemState, novoCep ).then( rs => {
        if ( rs ) {

          setDados( {
            ...dados,
            cep: novoCep,
            bairro: rs.bairro,
            cidade: rs.localidade,
            endereco: rs.logradouro,
            uf: rs.uf
          } )

        } else {
          setDados( { ...dados, cep: novoCep } )
        }
      } )

    }
  }

  const pesquisarcpf = ( valor: string ): Promise<boolean> => {

    return new Promise( ( resolve, _reject ) => {

      setDados( { ...dados, cpf: valor } )

      const query: string = `
        consultarClientePorCPF(cpf: "${valor}") {
          idCliente
        }
      `

      if ( valor.length > 0 && ( valor.length === 14 || valor.length === 18 ) ) {
        clsApi.query<ClienteInterface>( query, 'consultarClientePorCPF', 'Pesquisando documento...', contexto ).then( rs => {
          if ( rs && rs.idCliente && rs.idCliente !== dados.idCliente ) {
            setErros( { ...erros, cpf: 'Já Cadastrado!' } )
            setCpfJaCadastrado( true )
            resolve( false )
          } else {
            setErros( { ...erros, cpf: '' } )
            setCpfJaCadastrado( false )
            resolve( true )
          }
        } )
      } else {
        resolve( false )
      }

    } )
  }

  const btAtribuirSenha = ( rs: ClienteInterface ) => {
    setDados( rs )
    setAtribuirSenha( true )
  }

  const btVerificarPaciente = ( rs: ClienteInterface ) => {

    const query: string = `
      cadastradoComoPaciente(cpf: "${rs.cpf}") {
        ok
        mensagem
      }
    `

    clsApi.query<RespostaPadraoInterface>( query, 'cadastradoComoPaciente', 'Consultando Paciente...', contexto ).then( rsPaciente => {

      setMensagemState( { ...mensagemState, exibir: true, mensagem: rsPaciente.mensagem, exibirBotao: true, tipo: 'info', titulo: rs.nome } )

    } )

  }

  const btCancelarAtribuirSenha = () => {
    setAtribuirSenha( false )
  }

  const btResetTentativasAcessos = ( rs: ClienteInterface ) => {

    const mutation: string = `
      resetTentativasAcessos(idCliente: ${rs.idCliente}) {
        ok
        mensagem
      }
    `

    clsApi.mutation<RespostaPadraoInterface>( mutation, 'resetTentativasAcessos', 'Resetando as Tentativas de Acessos...', contexto ).then( rs => {

      if ( rs.ok ) {

        setMensagemState( {
          ...mensagemState,
          titulo: 'Confirmado!',
          exibir: true,
          mensagem: 'Reset de Tentativas Realizado!',
          tipo: MensagemTipo.Info,
          exibirBotao: true
        } )

        setDados( ResetDados )
        setAtribuirSenha( false )

      } else {

        setMensagemState( {
          ...mensagemState,
          titulo: 'Erro ao Resetar Tentativas de Acessos!',
          exibir: true,
          mensagem: rs.mensagem,
          tipo: MensagemTipo.Error,
          exibirBotao: true
        } )

      }

    } )

  }

  const atribuirSenhaCliente = ( idCliente: number, senha: string ) => {

    const mutation: string = `
      atribuirSenhaCliente(idCliente: ${idCliente}, senha: "${senha}") {
        ok
        mensagem
      }
    `

    clsApi.mutation<RespostaPadraoInterface>( mutation, 'atribuirSenhaCliente', 'Alterando a Senha do Cliente...', contexto ).then( rs => {

      if ( rs.ok ) {

        setMensagemState( {
          ...mensagemState,
          titulo: 'Confirmado!',
          exibir: true,
          mensagem: 'Senha Alterada com Sucesso!',
          tipo: MensagemTipo.Info,
          exibirBotao: true
        } )

        setDados( ResetDados )
        setAtribuirSenha( false )

      } else {

        setMensagemState( {
          ...mensagemState,
          titulo: 'Erro ao Alterar Senha!',
          exibir: true,
          mensagem: rs.mensagem,
          tipo: MensagemTipo.Error,
          exibirBotao: true
        } )

      }

    } )

  }

  const acoes = [
    { icone: 'delete', toolTip: 'Excluir', onAcionador: clsCrud.btExcluir },
    { icone: 'create', toolTip: 'Alterar', onAcionador: clsCrud.btEditar },
    { icone: 'restart_alt', toolTip: 'Reset Tentativas de Acessos', onAcionador: btResetTentativasAcessos },
    { icone: 'key', toolTip: 'Atribuir Senha', onAcionador: btAtribuirSenha }
  ]

  if ( process.env.REACT_APP_CLIENTE === "CSSJD" ) {
    acoes.unshift( { icone: 'help_outline', toolTip: 'Verificar se é Paciente', onAcionador: btVerificarPaciente } )
  }

  return (
    <>
      <Container maxWidth="md" sx={{ mt: 5 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                Cadastro de Clientes
                <Typography variant="body2" gutterBottom>
                  {TituloForm[statusForm]}
                </Typography>
              </Typography>

              <IconButton onClick={() => clsCrud.btFechar()}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <Condicional condicao={!atribuirSenha}>

              <Condicional condicao={statusForm === StatusForm.Pesquisando}>

                <Grid item xs={12} sm={8} sx={{ mb: 3 }}>

                  <InputText
                    dados={pesquisa}
                    field='descricao'
                    label='Pesquisar'
                    setState={setPesquisa}
                    iconeEnd="search"
                    onClickIconeEnd={() => clsCrud.onClickPesquisa( pesquisa, mensagemState )}
                    mapKeyPress={[{ key: 'Enter', onKey: onKeyPesquisa }]}
                  />

                </Grid>

                <Grid item xs={12} sm={2} sx={{ mb: 3, pl: { sm: 1 } }}>

                  <ComboBox
                    valorPadraoCampoEmBranco={'T'}
                    dados={pesquisa}
                    field='clientesAtivos'
                    label='Clientes Ativos?'
                    setState={setPesquisa}
                    campoDescricao='descricao'
                    campoID='idPesquisa'
                    opcoes={[{ idPesquisa: 'T', descricao: 'Todos' }, { idPesquisa: 'S', descricao: 'Sim' }, { idPesquisa: 'N', descricao: 'Não' }]}
                  />

                </Grid>

                <Grid item xs={12} sm={2} sx={{ mb: 3, pl: { sm: 1 } }}>

                  <ComboBox
                    valorPadraoCampoEmBranco={'T'}
                    dados={pesquisa}
                    field='clientesEmPreCadastro'
                    label='Pré Cadastro?'
                    setState={setPesquisa}
                    campoDescricao='descricao'
                    campoID='idPesquisa'
                    opcoes={[{ idPesquisa: 'T', descricao: 'Todos' }, { idPesquisa: 'S', descricao: 'Sim' }, { idPesquisa: 'N', descricao: 'Não' }]}
                  />

                </Grid>

                <Grid item xs={2} sm={6} md={8}></Grid>

                <Grid item xs={5} sm={3} md={2} alignSelf='center' sx={{ mt: { xs: 0, sm: 2, pr: 1 }, textAlign: { xs: 'right' } }}>
                  <Button variant='contained' onClick={() => clsCrud.btIncluir()}>Incluir</Button>
                </Grid>

                <Grid item xs={5} sm={3} md={2} alignSelf='center' sx={{ mt: { xs: 0, sm: 2 }, textAlign: { xs: 'right' } }}>
                  <Button variant='contained' onClick={() => clsCrud.onClickPesquisa( pesquisa, mensagemState )}>Pesquisar</Button>
                </Grid>

              </Condicional>

              <Condicional condicao={statusForm !== StatusForm.Pesquisando}>

                <Grid item xs={12} sm={4} md={3}>

                  <InputText
                    dados={dados}
                    field='cpf'
                    label='CPF'
                    setState={setDados}
                    disabled={statusForm === StatusForm.Excluindo}
                    erros={erros}
                    maxLength={14}
                    mask='cpf'
                    onChange={( v: string ) => pesquisarcpf( v )}
                  />

                </Grid>

                <Grid item xs={12} sm={8} md={9} sx={{ pl: { sm: 1 } }}>

                  <InputText
                    dados={dados}
                    field='nome'
                    label='Nome'
                    setState={setDados}
                    disabled={statusForm === StatusForm.Excluindo}
                    erros={erros}
                    maxLength={50}
                  />

                </Grid>

                <Grid item xs={12} sm={3} md={2}>

                  <InputText
                    dados={dados}
                    field='cep'
                    label='Cep'
                    setState={setDados}
                    disabled={statusForm === StatusForm.Excluindo}
                    erros={erros}
                    mask='cep'
                    onChange={( novoCep ) => alterarCep( novoCep )}
                  />

                </Grid>

                <Grid item xs={12} sm={9} md={10} sx={{ pl: { sm: 1 } }}>

                  <InputText
                    dados={dados}
                    field='endereco'
                    label='Endereço'
                    setState={setDados}
                    disabled={statusForm === StatusForm.Excluindo}
                    erros={erros}
                    maxLength={50}
                  />

                </Grid>

                <Grid item xs={12} md={5}>

                  <InputText
                    dados={dados}
                    field='bairro'
                    label='Bairro'
                    setState={setDados}
                    disabled={statusForm === StatusForm.Excluindo}
                    erros={erros}
                    maxLength={30}
                  />

                </Grid>

                <Grid item xs={12} sm={9} md={5} sx={{ pl: { sm: 1 } }}>

                  <InputText
                    dados={dados}
                    field='cidade'
                    label='Cidade'
                    setState={setDados}
                    disabled={statusForm === StatusForm.Excluindo}
                    erros={erros}
                    maxLength={35}
                  />

                </Grid>

                <Grid item xs={12} sm={3} md={2} sx={{ pl: { sm: 1 } }}>

                  <InputText
                    dados={dados}
                    field='uf'
                    label='UF'
                    setState={setDados}
                    disabled={statusForm === StatusForm.Excluindo}
                    erros={erros}
                    mask='uf'
                    tipo='uppercase'
                  />

                </Grid>

                <Grid item xs={12} >

                  <InputText
                    dados={dados}
                    field='email'
                    label='Email'
                    setState={setDados}
                    disabled={statusForm === StatusForm.Excluindo}
                    erros={erros}
                    maxLength={255}
                  />

                </Grid>

                <Grid item xs={12} sm={6} md={4} >

                  <InputText
                    dados={dados}
                    field='rg'
                    label='RG'
                    setState={setDados}
                    disabled={statusForm === StatusForm.Excluindo}
                    erros={erros}
                    maxLength={25}
                  />

                </Grid>

                <Grid item xs={12} sm={6} md={4} sx={{ pl: { sm: 1 } }}>
                  <InputText
                    dados={dados}
                    field='whatsAPP'
                    label='Whats App'
                    setState={setDados}
                    disabled={statusForm === StatusForm.Excluindo}
                    erros={erros}
                    maxLength={15}
                    mask='tel'
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} sx={{ pl: { sm: 1 } }}>
                  <InputText
                    dados={dados}
                    field='fone'
                    label='Fone'
                    setState={setDados}
                    disabled={statusForm === StatusForm.Excluindo}
                    erros={erros}
                    maxLength={15}
                    mask='tel'
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3} sx={{ pl: { xs: 0, sm: 1, md: 0 } }}>

                  <PesquisarTabela<PerfilInterface>
                    setState={setDados}
                    field='idPerfil'
                    fieldSet='idPerfil'
                    label='Perfil'
                    dados={dados}
                    campoQueryPesquisaID='idPerfil'
                    campoQueryPesquisa='pesquisa'
                    camposRetornoQueryPesquisa='{idPerfil, descricao}'
                    campoLabelQueryPesquisa='descricao'
                    nomeQueryPesquisa='getPerfis'
                    nomeQueryPesquisaID='getPerfilPorId'
                    mensagemPesquisa='Procurando Perfis...'
                    disabled={statusForm === StatusForm.Excluindo}
                    erros={erros}
                    pesquisarTudoAoIniciar
                  />

                </Grid>

                <Grid item xs={12} sm={4} md={3} sx={{ pl: { sm: 1 } }} >

                  <InputText
                    dados={dados}
                    field='velocidade'
                    label='Velocidade'
                    setState={setDados}
                    disabled={statusForm === StatusForm.Excluindo}
                    erros={erros}
                    maxLength={5}
                  />

                </Grid>

                <Grid item xs={12} sm={4} md={3} sx={{ pl: { sm: 1 } }} >

                  <InputText
                    dados={dados}
                    field='sessoesSimultaneas'
                    label='Sessões Simultâneas'
                    tipo='number'
                    setState={setDados}
                    disabled={statusForm === StatusForm.Excluindo}
                    erros={erros}
                    maxLength={3}
                  />

                </Grid>

                <Grid item xs={12} sm={4} md={3} sx={{ pl: { sm: 1 } }}>

                  <InputText
                    dados={dados}
                    field='dataLimite'
                    label='Data Limite'
                    setState={setDados}
                    disabled={statusForm === StatusForm.Excluindo}
                    erros={erros}
                    type='date'
                  />

                </Grid>
                <Grid item sm={4} md={8}>
                </Grid>

                <Grid item xs={12} sm={4} md={2} sx={{ mt: { xs: 2, sm: 4 }, pl: { md: 1 } }}>
                  <InputText
                    dados={dados}
                    field='ativo'
                    label='Ativo'
                    setState={setDados}
                    tipo='checkbox'
                    disabled={statusForm === StatusForm.Excluindo}
                    erros={erros}
                  />
                </Grid>

                <Grid item xs={12} sm={4} md={2} sx={{ mt: { xs: 2, sm: 4 }, pl: { sm: 1 } }}>
                  <InputText
                    dados={dados}
                    field='preCadastro'
                    label='Pré Cadastro'
                    setState={setDados}
                    tipo='checkbox'
                    disabled={statusForm === StatusForm.Excluindo}
                    erros={erros}
                  />
                </Grid>

                <Grid item xs={12} sx={{ mt: 3 }}>

                  <Condicional condicao={statusForm === StatusForm.Excluindo}>
                    <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => clsCrud.btConfirmarExclusao( dados, mensagemState, pesquisa )}>Confirmar</Button>
                  </Condicional>

                  <Condicional condicao={statusForm !== StatusForm.Excluindo}>
                    <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => clsCrud.btConfirmar( dados, mensagemState, statusForm, pesquisa )}>Confirmar</Button>
                  </Condicional>

                  <Button variant='contained' startIcon={<CloseIcon />} sx={{ py: 1 }} onClick={() => clsCrud.btCancelar()}>Cancelar</Button>

                </Grid>

              </Condicional>

              <Condicional condicao={statusForm === StatusForm.Pesquisando}>
                <Grid item xs={12} sx={{ mt: 3 }}>
                  <DataTable dados={rsPesquisa} cabecalho={Cabecalho} acoes={acoes} />
                </Grid>
              </Condicional>

            </Condicional>

            <ClientesAtribuirSenha
              atribuirSenha={atribuirSenha}
              dados={dados}
              onConfirmar={atribuirSenhaCliente}
              onCancelar={btCancelarAtribuirSenha}
            />

            <Condicional condicao={EMDESENVOLVIMENTO}>
              <p>{JSON.stringify( dados )}</p>
              <p>{JSON.stringify( erros )}</p>
            </Condicional>

          </Grid>
        </Paper>

      </Container>
    </>
  )
}