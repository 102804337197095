import React, { useEffect } from 'react'

export default function TermoAceite ( { termoAceite }: { termoAceite: string } ) {

  useEffect( () => {
    ( document.getElementById( 'txtTermoAceite' ) as HTMLElement ).innerHTML = termoAceite
  }, [termoAceite] )

  return (
    <>
      <div id="txtTermoAceite"></div>
      {/*
    
      <Typography variant='h6' sx={{ textAlign: 'center', mt: 5 }}>
        TERMO ACEITE WIFI
      </Typography>
      <Typography variant='body1' sx={{ textAlign: 'justify', mt: espacamentoSuperiorParagrafo }}>
        {`Para utilizar o Wi-Fi disponibilizado pel${process.env.REACT_APP_A_EMPRESA as string} para acesso à
        Internet, o USUÁRIO expressamente aceita, sem reservas ou ressalvas, todas
        as condições abaixo descritas.`}
      </Typography>
      <Typography variant='body1' sx={{ textAlign: 'justify', mt: espacamentoSuperiorParagrafo }}>
        {`I. O Wi-Fi disponibilizado pel${process.env.REACT_APP_A_EMPRESA as string} permite acesso a sites na
        internet, envio e recebimento de mensagens instantâneas através de
        aplicativos públicos e a utilização de correio eletrônico.`}
      </Typography>
      <Typography variant='body1' sx={{ textAlign: 'justify', mt: espacamentoSuperiorParagrafo }}>
        II. O acesso à internet pelo Wi-Fi não gera qualquer direito ao USUÁRIO e
        poderá ser interrompido a qualquer momento sem prévio aviso.
      </Typography>
      <Typography variant='body1' sx={{ textAlign: 'justify', mt: espacamentoSuperiorParagrafo }}>
        III. O USUÁRIO deverá dispor e manter o equipamento necessário compatível
        com a tecnologia Wi-Fi para ter acesso à internet em condições de
        funcionamento, bem como deverá promover as medidas de segurança necessárias
        à proteção de seus equipamentos, sistemas e arquivos contra a atuação
        indevida e invasões não autorizadas de outros usuários de internet.
      </Typography>
      <Typography variant='body1' sx={{ textAlign: 'justify', mt: espacamentoSuperiorParagrafo }}>
        {`IV. O USUÁRIO não poderá utilizar o Wi-Fi disponibilizado pel${process.env.REACT_APP_A_EMPRESA as string}
        para acessar, propagar ou manter portal ou site(s) na internet com conteúdo
        que:`}
      </Typography>
      <ul>
        <li>
          Violem a lei ou não sejam autorizados;
        </li>

        <li>Ofendam os direitos à honra, à vida privada, à imagem, à intimidade pessoal e familiar de quem quer que seja,
          assim como a propriedade intelectual;
        </li>
        <li>Estimulem a prática de condutas contrárias à moral e aos bons costumes;</li>
        <li>Incitem a prática de atos discriminatórios, seja em razão de
          sexo, raça, religião, crença, idade, ideologia ou qualquer outra condição;
        </li>
        <li>Coloquem à disposição ou possibilitem o acesso às mensagens,
          produtos ou serviços ilícitos, inapropriados, difamatórios, violentos,
          obscenos e pornográficos;
        </li>
        <li>Induzam ou incitem práticas perigosas, de risco ou nocivas para
          a saúde e para o equilíbrio psíquico;
        </li>
        <li>Sejam falsos, ambíguos, inexatos, exagerados ou extemporâneos,
          de forma que possam induzir a erro sobre seu objeto ou sobre as intenções
          ou propósitos do comunicador;
        </li>
        <li>
          Violem o sigilo das comunicações;
        </li>
        <li>Constituam publicidade ilícita, enganosa ou desleal, em geral,
          que configurem concorrência desleal e/ou denominados "spam-mails";
        </li>
        <li>Veiculem, incitem ou estimulem a pedofilia; e/ou,</li>
        <li>Incorporem vírus ou outros elementos físicos ou eletrônicos que
          possam danificar ou impedir o normal funcionamento da rede, do sistema ou
          dos equipamentos (hardware e software) de terceiros ou que possam danificar
          os documentos eletrônicos e arquivos armazenados nestes equipamentos.
        </li>
      </ul>

      <Typography variant='body1' sx={{ textAlign: 'justify', mt: espacamentoSuperiorParagrafo }}>
        {`V. ${process.env.REACT_APP_A_EMPRESA_MAIUSCULA as string} não se responsabiliza, direta ou indiretamente, por
        quaisquer despesas, danos ou perdas que sejam efetiva ou alegadamente
        causados por quaisquer conteúdos, produtos ou serviços disponíveis em sites
        de terceiros ou recursos externos, não garantindo a perfeição, qualidade,
        veracidade, adequação, utilidade ou segurança do conteúdo ou de qualquer
        serviço oferecido na internet, inclusive, mas não se limitando a, serviços
        envolvendo compra e/ou venda, investimentos, seguros, aplicações,
        transferências de valores e demais operações financeiras, ou pela
        utilização ou confiança depositada pelo USUÁRIO em tais conteúdos, produtos
        ou serviços.`}
      </Typography>
      <Typography variant='body1' sx={{ textAlign: 'justify', mt: espacamentoSuperiorParagrafo }}>
        {`VI. Embora ${process.env.REACT_APP_A_EMPRESA as string} utilize as melhores tecnologias e empenhe seus
        maiores esforços, não há condições de controle que garanta a ausência de
        vírus nos conteúdos transmitidos, infundidos, armazenados, recebidos,
        obtidos, colocados à disposição, ou acessíveis por meio da utilização do
        Wi-Fi, nem a ausência de outros elementos que possam produzir alterações no
        equipamento do USUÁRIO ou nos documentos eletrônicos e pastas armazenadas
        ou transmitidas desde o equipamento do USUÁRIO.`}
      </Typography>
      <Typography variant='body1' sx={{ textAlign: 'justify', mt: espacamentoSuperiorParagrafo }}>
        {`VII. Assim, ${process.env.REACT_APP_A_EMPRESA as string} SE EXIME DE QUALQUER RESPONSABILIDADE PELOS DANOS E
        PREJUÍZOS DE QUALQUER NATUREZA QUE POSSAM DECORRER DA PRESENÇA DE VÍRUS OU
        DE OUTROS ELEMENTOS NOCIVOS NOS CONTEÚDOS DA INTERNET E QUE, DESTA FORMA,
        POSSAM PRODUZIR ALTERAÇÕES E/ OU DANOS NO SISTEMA FÍSICO E/ OU ELETRÔNICO
        DOS EQUIPAMENTOS DO USUÁRIO.`}
      </Typography>
      <Typography variant='body1' sx={{ textAlign: 'justify', mt: espacamentoSuperiorParagrafo }}>
        {`VIII. ${process.env.REACT_APP_A_EMPRESA_MAIUSCULA as string} não tem obrigação de controlar e não controla o conteúdo
        e natureza dos conteúdos transmitidos, difundidos ou postos à disposição de
        terceiros pelos USUÁRIOS através do Wi-Fi. INDEPENDENTE DISSO, ${process.env.REACT_APP_A_EMPRESA_MAIUSCULA as string}
        TERÁ O DIREITO DE REGISTRAR O ACESSO COM CREDENCIAIS DE USUÁRIO, REVISAR, A
        QUALQUER MOMENTO E SEM AVISO PRÉVIO, POR PRÓPRIA INICIATIVA, A SEU CRITÉRIO
        OU A PEDIDO DE TERCEIRO, OS CONTEÚDOS TRANSMITIDOS, DIFUNDIDOS OU POSTOS À
        DISPOSIÇÃO DE TERCEIROS PELOS USUÁRIOS ATRAVÉS DO WI-FI E IMPEDIR SUA
        TRANSMISSÃO, DIFUSÃO OU COLOCAÇÃO A DISPOSIÇÃO DE TERCEIROS, QUANDO ESSES
        CONTEÚDOS ESTIVEREM EM DESACORDO COM O DISPOSTO NESTE TERMO.`}
      </Typography>
      <Typography variant='body1' sx={{ textAlign: 'justify', mt: espacamentoSuperiorParagrafo }}>
        AO CHECAR O ITEM “EU CONCORDO COM OS TERMOS DE USO” SIGNIFICA QUE VOCÊ
      </Typography>
      <ul>
        <li>
          LEU E COMPREENDEU TODO O CONTEÚDO DESTE TERMO;
        </li>
        <li>ESTÁ DE PLENO ACORDO COM TODAS AS CONDIÇÕES DESTE TERMO; E,</li>
        <li>TEM CAPACIDADE LEGAL PARA SE RESPONSABILIZAR PELAS OBRIGAÇÕES
          AQUI ASSUMIDAS.</li>
      </ul>
      */}
    </>
  )

}