import React, { useContext, useState, useEffect } from 'react'
import { ParametrosInterface } from '../../ImportBackend/Interfaces/ParametrosInterfaces'
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal'
import BackEndAPI from '../../Services/BackEndAPI'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { Box, Button, Container, Grid, IconButton, InputLabel, Paper, TextField, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import InputText from '../../DevComponents/InputText'
import PesquisarTabela from '../../DevComponents/PesquisarTabela'
import { PerfilInterface } from '../../ImportBackend/Interfaces/PerfilInterfaces'
import ClsValidacao from '../../Utils/ClsValidacao'
import ClsUtils from 'zlib-utils'
import { RespostaPadraoInterface } from '../../ImportBackend/Interfaces/PadraoInterfaces'
import { MensagemTipo } from '../../GlobalStates/MensagemState'

import axios from 'axios'
import UploadIcon from '@mui/icons-material/Upload'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { ENDPOINT } from '../../ImportBackend/Config/emDesenvolvimento'
import Condicional from '../../Layout/Condicional'


export default function Parametros () {

  const [txtTermoPreCadastro, setTxtTermoPreCadastro] = useState( '' )

  const abortController: AbortController = new AbortController()

  const contextoGlobal = useContext( ContextoGlobal ) as ContextoGlobalInterface

  const { mensagemState, setMensagemState } = ( useContext( ContextoGlobal ) as ContextoGlobalInterface )

  const clsApi = new BackEndAPI()

  const ResetDados: ParametrosInterface = {
    idParametro: 0,
    preCadastroSiteRedirecionamento: '',
    preCadastroClienteIdPerfilPadrao: 0,
    preCadastroSessoesSimultaneas: 0,
    preCadastroAtivacaoAutomatica: false,
    emailNome: '',
    emailEmail: '',
    emailSenha: '',
    emailHost: '',
    emailPorta: '',
    termoPreCadastro: '',
    arquivoPreCadastro: ''
  }

  const [dados, setDados] = useState<ParametrosInterface>( ResetDados )
  const [erros, setErros] = useState( {} )

  const navigate = useNavigate()

  const validarDados = (): boolean => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.naoVazio( 'preCadastroSiteRedirecionamento', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'preCadastroClienteIdPerfilPadrao', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'preCadastroSessoesSimultaneas', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'emailNome', dados, erros, retorno )
    retorno = clsValidacao.eEmail( 'emailEmail', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'emailSenha', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'emailHost', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'emailPorta', dados, erros, retorno )

    setErros( erros )

    return retorno

  }

  const btFechar = () => {
    navigate( '/' )
  }

  const btConfirmar = () => {

    if ( validarDados() ) {

      const mutation: string = `
        updateParametros (dados: ${( new ClsUtils() ).ConverterEmGql( { ...dados, termoPreCadastro: txtTermoPreCadastro } )} ) {
          ok
          mensagem
        }
      `

      clsApi.mutation<RespostaPadraoInterface>( mutation, 'updateParametros', 'Atualizando Parâmetros...', contextoGlobal ).then( rs => {

        if ( rs.ok ) {

          setMensagemState( {
            ...mensagemState,
            exibir: true,
            mensagem: 'Parâmetros Atualizados!',
            tipo: MensagemTipo.Info,
            exibirBotao: true
          } )

        }

      } )

    }
  }

  useEffect( () => {

    const query: string = `
      getParametros {
        idParametro
        preCadastroSiteRedirecionamento
        preCadastroClienteIdPerfilPadrao
        preCadastroSessoesSimultaneas
        preCadastroAtivacaoAutomatica
        emailNome
        emailEmail
        emailSenha
        emailHost
        emailPorta
        termoPreCadastro
        arquivoPreCadastro
      }
    `

    clsApi.query<ParametrosInterface>( query, 'getParametros', 'Pesquisando Parâmetros...', contextoGlobal, abortController ).then( rs => {

      setMensagemState( { ...mensagemState, exibir: false } )
      setDados( rs )
      setTxtTermoPreCadastro( rs.termoPreCadastro )

    } ).catch( e => {
      console.log( e )
    } )

    return () => {

      abortController.abort()

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] )

  const getToken = () => {
    const token = localStorage.getItem( 'token' )
    return token ? token : ''
  }

  const btUpload = () => {

    if ( validarDados() ) {

      let input: HTMLInputElement = document.createElement( 'input' )

      input.type = 'file'
      input.name = 'arquivo'

      input.onchange = () => {

        if ( input.files ) {

          const formulario: FormData = new FormData()

          formulario.append( 'arquivo', input.files[0] )
          formulario.append( 'nomeArquivo', input.files[0].name )
          formulario.append( 'token', getToken() )

          setMensagemState( {
            mensagem: 'Carregando Arquivo...',
            exibir: true,
            exibirBotao: true,
            titulo: 'Aguarde!',
            tipo: 'Loading',
            cb: null
          } )

          axios.post( ENDPOINT.concat( 'uploadTermoPreCadastro' ), formulario, {
            headers: {
              'content-type': 'multipart/form-data'
            } //,
            // onUploadProgress: this.calculaprogressoUpload
          } ).then( rs => {

            console.log( rs.data )

            if ( !rs.data.ok ) {

              setMensagemState( {
                mensagem: rs.data.mensagem,
                exibir: true,
                exibirBotao: true,
                titulo: 'Erro!',
                tipo: 'error',
                cb: null
              } )

            } else {
              setMensagemState( {
                mensagem: rs.data.mensagem,
                exibir: true,
                exibirBotao: true,
                titulo: 'Sucesso!',
                tipo: 'info',
                cb: null
              } )
              setDados( { ...dados, arquivoPreCadastro: ( input.files as FileList )[0].name } )
            }

          } ).catch( () => {

            console.log( 'Dentro do Catch....' )

            setMensagemState( {
              mensagem: 'Erro no Servidor!',
              exibir: true,
              exibirBotao: true,
              titulo: 'Consulte Suporte!',
              tipo: 'error',
              cb: null
            } )
          } )

        }

      }

      input.click()

    }

  }

  const btApagarArquivo = () => {

    const mutation: string = `
      apagarArquivoPreCadastro {
        ok
        mensagem
      }
    `

    clsApi.mutation<RespostaPadraoInterface>( mutation, 'apagarArquivoPreCadastro', 'Apagando Arquivo...', contextoGlobal ).then( rs => {

      if ( rs.ok ) {

        setMensagemState( {
          ...mensagemState,
          exibir: true,
          mensagem: 'Arquivo Apagado!',
          tipo: MensagemTipo.Info,
          exibirBotao: true
        } )

        setDados( { ...dados, arquivoPreCadastro: '' } )

      } else {
        setMensagemState( {
          ...mensagemState,
          exibir: true,
          mensagem: 'Arquivo Não Apagado!',
          tipo: MensagemTipo.Error,
          exibirBotao: true
        } )
      }

    } )

  }

  return (
    <>
      <Container maxWidth="md" sx={{ mt: 5 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                Parâmetros
              </Typography>

              <IconButton onClick={() => btFechar()}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <Grid item xs={12}>

              <InputText
                dados={dados}
                field='preCadastroSiteRedirecionamento'
                label='Site Redirecionamento Pré Cadastro'
                setState={setDados}
                erros={erros}
                maxLength={255}
              />

            </Grid>

            <Grid item xs={12} sm={6}>

              <PesquisarTabela<PerfilInterface>
                setState={setDados}
                field='idPerfil'
                fieldSet='preCadastroClienteIdPerfilPadrao'
                label='Perfil Cliente Padrão'
                dados={dados}
                campoQueryPesquisaID='idPerfil'
                campoQueryPesquisa='pesquisa'
                camposRetornoQueryPesquisa='{idPerfil, descricao}'
                campoLabelQueryPesquisa='descricao'
                nomeQueryPesquisa='getPerfis'
                nomeQueryPesquisaID='getPerfilPorId'
                mensagemPesquisa='Procurando Perfis...'
                erros={erros}
                pesquisarTudoAoIniciar
              />

            </Grid>

            <Grid item xs={6} sm={3} sx={{ mt: 4, pl: { sm: 1 } }}>
              <InputText
                dados={dados}
                field='preCadastroAtivacaoAutomatica'
                label='Ativação Automática'
                setState={setDados}
                tipo='checkbox'
                erros={erros}
              />
            </Grid>

            <Grid item xs={6} sm={3} sx={{ pl: { sm: 1 } }} >

              <InputText
                dados={dados}
                field='preCadastroSessoesSimultaneas'
                label='Sessões Simultâneas'
                tipo='number'
                setState={setDados}
                erros={erros}
                maxLength={3}
              />

            </Grid>

            <Grid item xs={12} >

              <InputText
                dados={dados}
                field='emailNome'
                label='Nome para e-mail'
                setState={setDados}
                erros={erros}
                maxLength={50}
              />

            </Grid>

            <Grid item xs={12} >

              <InputText
                dados={dados}
                field='emailEmail'
                label='Endereço do e-mail'
                setState={setDados}
                erros={erros}
                maxLength={255}
              />

            </Grid>

            <Grid item xs={12} sm={5} >

              <InputText
                dados={dados}
                field='emailSenha'
                label='Senha do e-mail'
                setState={setDados}
                erros={erros}
                maxLength={25}
              />

            </Grid>

            <Grid item xs={12} sm={5} sx={{ pl: { sm: 1 } }} >

              <InputText
                dados={dados}
                field='emailHost'
                label='Host do servidor de e-mail'
                setState={setDados}
                erros={erros}
                maxLength={25}
              />

            </Grid>

            <Grid item xs={12} sm={2} sx={{ pl: { sm: 1 } }} >

              <InputText
                dados={dados}
                field='emailPorta'
                label='Porta'
                setState={setDados}
                erros={erros}
                maxLength={5}
              />

            </Grid>

            <Grid item xs={12}>
              <Box sx={{ mt: 3, display: 'flex', flexDirection: 'row' }}>

                <TextField fullWidth disabled label="Arquivo para Download na tela de Pré Cadastro" value={dados.arquivoPreCadastro}></TextField>

                <Condicional condicao={dados.arquivoPreCadastro.length > 0}>
                  <IconButton sx={{ mt: 1 }} onClick={() => btApagarArquivo()} >
                    <HighlightOffIcon />
                  </IconButton>
                </Condicional>

                <Condicional condicao={dados.arquivoPreCadastro.length === 0}>
                  <IconButton sx={{ mt: 1 }} onClick={() => btUpload()} >
                    <UploadIcon />
                  </IconButton>
                </Condicional>
              </Box>
            </Grid>

            <Grid item xs={12} sx={{ pt: 2 }} >

              <InputLabel>Texto para tela de Pré Cadastro</InputLabel>
              <ReactQuill theme="snow" value={txtTermoPreCadastro} onChange={( v ) => {
                console.log( 'On Change ReactQuill' )
                setTxtTermoPreCadastro( v )
              }}
              />

            </Grid>

            <Grid item xs={12} sx={{ mt: 3 }}>
              <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => btConfirmar()}>Confirmar</Button>
            </Grid>

          </Grid>

        </Paper>
      </Container>

    </>
  )

}