import React, { useState, useContext, useEffect, useRef } from 'react'
import TextField from '@mui/material/TextField/TextField'
import FormControl from '@mui/material/FormControl'
import Typography from '@mui/material/Typography'
import { useTheme, IconButton, Grid, Autocomplete } from '@mui/material'
import BackEndAPI from '../Services/BackEndAPI'
import { ContextoGlobal, ContextoGlobalInterface } from '../GlobalStates/ContextoGlobal'
import Condicional from '../Layout/Condicional'
import { MensagemTipo } from '../GlobalStates/MensagemState'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'

export interface pesquisarTabelaPropsInterface<T> {
  /* State para setup dos dados */
  setState?: React.Dispatch<React.SetStateAction<any>>
  /* Campo dentro do State a ser Setado */
  field: string,
  /* Campo retornado da Pesquisa para ser usado como ID */
  fieldSet: string,
  /* Label do Campo (Acima do texto) */
  label: string
  /* Dados Originais */
  dados: { [key: string]: string | number | readonly string[] | undefined | any },
  /* Campo 'Pesquisa' que normalmente as querys de pesquisa possuem - pesquisa geral */
  campoQueryPesquisa: string,
  /* Nome da Query Pesquisa */
  nomeQueryPesquisa: string,
  /* JSON de fields que serão retornados da pesquisa */
  camposRetornoQueryPesquisa: string,
  /* Label da Query de Pesquisa que será apresentado ao usuário */
  campoLabelQueryPesquisa: string,
  /* Pesquisa pelo ID - Quando há ID para ser procurado e ser exibido inicialmente */
  campoQueryPesquisaID: string,
  /* Query para Pesquisa inicial do ID inicial do campo */
  nomeQueryPesquisaID: string,
  /* Mensagem enquanto ocorre o Loading da Pesquisa */
  mensagemPesquisa: string
  /* Objeto Erros */
  erros?: { [key: string]: string },
  disabled?: boolean
  onChange?: ( v: T ) => void
  /* Se realiza uma pesquisa com argumento 'vazio' e retorna todas as pesquisas possível */
  pesquisarTudoAoIniciar?: boolean

  /* Valor a Ser atribuido quando usuário "clica" no "x" */
  valorAtribuirLimpar?: number | string | boolean
}

export default function PesquisarTabela<T> ( {
  setState = undefined,
  field,
  fieldSet,
  label,
  dados,
  campoQueryPesquisa,
  nomeQueryPesquisa,
  campoQueryPesquisaID,
  camposRetornoQueryPesquisa,
  campoLabelQueryPesquisa,
  nomeQueryPesquisaID,
  mensagemPesquisa,
  erros = {},
  disabled = false,
  onChange = undefined,
  pesquisarTudoAoIniciar = false,
  valorAtribuirLimpar = undefined
}: pesquisarTabelaPropsInterface<T> ) {

  const contexto: ContextoGlobalInterface = ( useContext( ContextoGlobal ) as ContextoGlobalInterface )

  const theme = useTheme()
  const clsApi = new BackEndAPI()

  const [pesquisa, setPesquisa] = useState( '' )

  const [rsPesquisa, setRsPesquisa] = useState( [] as Array<any> )

  const refUltimaPesquisa = useRef<{ pesquisa: string | null, retorno: Array<any> }>( { pesquisa: null, retorno: [] } )

  const onClickSearch = () => {

    if ( refUltimaPesquisa.current.pesquisa !== pesquisa ) {

      const query: string = `
      ${nomeQueryPesquisa}(${campoQueryPesquisa}: "${pesquisa}") ${camposRetornoQueryPesquisa}
      `

      clsApi.query<Array<any>>( query, nomeQueryPesquisa, mensagemPesquisa, contexto ).then( rs => {
        refUltimaPesquisa.current = {
          pesquisa: pesquisa,
          retorno: rs
        }
        setRsPesquisa( rs )
      } )

    } else {
      setRsPesquisa( refUltimaPesquisa.current.retorno )
    }

  }

  const pesquisaValorInicialCampoPorId = () => {

    // Pesquisa caso haja um campo....
    if ( dados[fieldSet] > 0 ) {

      const query: string = `
      ${nomeQueryPesquisaID}(${campoQueryPesquisaID}: ${dados[fieldSet]}) ${camposRetornoQueryPesquisa}
    `

      clsApi.query<T>( query, nomeQueryPesquisaID, mensagemPesquisa, contexto, abortController ).then( rs => {

        setRsPesquisa( [rs] )

      } ).catch( ( e ) => {

        contexto.setMensagemState( {
          ...contexto.mensagemState,
          titulo: 'Erro! Consulte Suporte!',
          exibir: true,
          mensagem: 'Erro ao Consultar Search Text!',
          tipo: MensagemTipo.Error,
          exibirBotao: true
        } )

      } )

      return () => {

        abortController.abort()

      }

    }
  }

  // Pesquisa Quando o campo já vem preenchido...

  const abortController: AbortController = new AbortController()

  const idPesquisado = useRef( -1 )

  useEffect( () => {

    if ( pesquisarTudoAoIniciar && idPesquisado.current !== dados[fieldSet] ) {

      onClickSearch()

    } else {
      if ( idPesquisado.current !== dados[fieldSet] ) {
        idPesquisado.current = dados[fieldSet]
        pesquisaValorInicialCampoPorId()
      }

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dados[fieldSet]] )
  // }, [keyRefresh] )

  /*
  Auto COmplete...

filterOptions={( x ) => x}
disablePortal
handleHomeEndKeys

  */

  return (
    <>
      <Autocomplete
        value={dados[fieldSet]}
        disabled={disabled}
        id="idPesquisarTabelaComponente"
        getOptionLabel={( opcao: any ) => {
          if ( opcao && opcao[campoLabelQueryPesquisa] ) {
            return opcao[campoLabelQueryPesquisa]
          } else {

            const retorno = rsPesquisa.find( ( v ) => v[campoQueryPesquisaID] === opcao )

            if ( retorno && retorno[campoLabelQueryPesquisa] ) {
              return retorno[campoLabelQueryPesquisa]
            } else {
              return ''
            }

          }
        }
        }
        onChange={( _e, v ) => {
          if ( onChange ) {
            onChange( v )
          } else if ( setState ) {
            setState( { ...dados, [fieldSet]: v !== null ? v[field] : typeof valorAtribuirLimpar !== 'undefined' ? valorAtribuirLimpar : v } )
          }
        }}
        isOptionEqualToValue={
          ( _opcao, _valor ) => {
            return true
          }
        }
        options={rsPesquisa}
        inputValue={pesquisa}
        onInputChange={( _event: React.SyntheticEvent, value: string, reason: string ) => {
          if ( value ) { setPesquisa( value ) } else { setPesquisa( '' ) }
        }}
        renderOption={( props, option: any ) => {
          return (
            <li {...props} key={option[field]}>
              <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    variant='body2'
                    textAlign='left'
                    sx={{ mt: theme.inputs.marginTop }}
                  >
                    {option[campoLabelQueryPesquisa]}
                  </Typography>
                  {/*
                  Campo Secundário de Exibição...
                  <Typography
                    variant='body2'
                    textAlign='left'
                    sx={{ mt: theme.inputs.marginTop }}
                  >
                    {option[field]}cccc
                  </Typography>
                  */}
                </Grid>
              </Grid>
            </li>
          );
        }}
        renderInput={( params ) =>
          <FormControl sx={{ width: '100%' }}>
            <Typography
              variant='body2'
              textAlign='left'
              sx={{ mt: theme.inputs.marginTop }}
            >
              {label}
            </Typography>
            <TextField {...params}

              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={() => onClickSearch()}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}

              size="small" onKeyDown={
                ( ev ) => {

                  if ( ev.key === 'Enter' ) {
                    onClickSearch()
                  }

                  return null

                }
              } />
            <Condicional condicao={typeof erros[fieldSet] !== 'undefined'}>
              <Typography variant='caption' textAlign='left' color='warning.main' >{erros[fieldSet]}</Typography>
            </Condicional>
          </FormControl>
        }
        onKeyDown={( event ) => {
          if ( event.key === 'Enter' ) {
            event.preventDefault()
          }
        }}
      />
    </>
  )

}